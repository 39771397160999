import React from 'react';
import { translate, Trans } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import defaultImage from '../../../assets/img/image_placeholder.jpg';

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroups: []
    };
  }

  onChange(event) {
    let { selectedGroups } = this.state;

    if (event.target.checked) {
      selectedGroups.push(event.target.value);
    } else {
      let index = selectedGroups.findIndex(g => g === event.target.value);

      if (index > -1) selectedGroups.splice(index, 1);
    }

    this.setState(
      { selectedGroups },
      this.props.onChangeGroups(selectedGroups)
    );
  }

  render() {
    let { groups } = this.props,
      { selectedGroups } = this.state;

    return (
      <div>
        <h5 className="info-text">
          <Trans>Select the surfaces</Trans>
        </h5>
        <Row className="justify-content-center">
          {groups.map((group, key) => {
            return (
              <Col key={key} xs={12} sm={4} md={4} lg={3}>
                <input
                  id={group._id}
                  className="input-hidden"
                  type="checkbox"
                  value={group._id}
                  checked={selectedGroups.findIndex(g => g === group._id) > -1}
                  onChange={event => this.onChange(event)}
                />
                <label htmlFor={group._id}>
                  <img
                    src={group.photo ? group.photo : defaultImage}
                    alt={group.name}
                  />
                  <h6 className={'text-center mt-1'}>{group.name}</h6>
                </label>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

export default translate('translations-fr')(Step2);
