// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * API - price
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.3.4
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as url from 'url';
import * as portableFetch from 'portable-fetch';
const BASE_PATH = 'https://api-gateway.leroymerlin.fr/api-price/v1';
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|'
};
/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  constructor(configuration, basePath = BASE_PATH, fetch = portableFetch) {
    this.basePath = basePath;
    this.fetch = fetch;
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  constructor(field, msg) {
    super(msg);
    this.field = field;
  }
}

/**
 * OprationsConcernantLesPrixDachatsApi - fetch parameter creator
 * @export
 */
export const OprationsConcernantLesPrixDachatsApiFetchParamCreator = function(
  configuration
) {
  return {
    /**
     *
     * @summary Récupère les prix d'achats
     * @param {string} productIds identifiants des produits
     * @param {string} xAccessToken Access Token oauth du client
     * @param {number} [storeId] identifiant du magasin (Obligatoire)
     * @param {number} [operationId] identifiant de l&#39;opération commerciale
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {boolean} [lastActivePurchaseCondition] permet de retourner la dernière condition d&#39;achat même périmée
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      productIds,
      xAccessToken,
      storeId,
      operationId,
      fl,
      lastActivePurchaseCondition,
      options = {}
    ) {
      // verify required parameter 'productIds' is not null or undefined
      if (productIds === null || productIds === undefined) {
        throw new RequiredError(
          'productIds',
          'Required parameter productIds was null or undefined when calling get.'
        );
      }
      // verify required parameter 'xAccessToken' is not null or undefined
      if (xAccessToken === null || xAccessToken === undefined) {
        throw new RequiredError(
          'xAccessToken',
          'Required parameter xAccessToken was null or undefined when calling get.'
        );
      }
      const localVarPath = `/prices/purchase`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (productIds !== undefined) {
        localVarQueryParameter['productIds'] = productIds;
      }
      if (storeId !== undefined) {
        localVarQueryParameter['storeId'] = storeId;
      }
      if (operationId !== undefined) {
        localVarQueryParameter['operationId'] = operationId;
      }
      if (fl) {
        localVarQueryParameter['fl'] = fl;
      }
      if (lastActivePurchaseCondition !== undefined) {
        localVarQueryParameter[
          'lastActivePurchaseCondition'
        ] = lastActivePurchaseCondition;
      }
      if (xAccessToken !== undefined && xAccessToken !== null) {
        localVarHeaderParameter['X-Access-Token'] = String(xAccessToken);
      }
      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Récupère les prix d'achats - POST
     * @param {ProductsPostParamVO} body objet json
     * @param {string} xAccessToken Access Token oauth du client (authentification faible)
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(body, xAccessToken, fl, options = {}) {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling post.'
        );
      }
      // verify required parameter 'xAccessToken' is not null or undefined
      if (xAccessToken === null || xAccessToken === undefined) {
        throw new RequiredError(
          'xAccessToken',
          'Required parameter xAccessToken was null or undefined when calling post.'
        );
      }
      const localVarPath = `/prices/purchase`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (fl) {
        localVarQueryParameter['fl'] = fl;
      }
      if (xAccessToken !== undefined && xAccessToken !== null) {
        localVarHeaderParameter['X-Access-Token'] = String(xAccessToken);
      }
      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        'ProductsPostParamVO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};
/**
 * OprationsConcernantLesPrixDachatsApi - functional programming interface
 * @export
 */
export const OprationsConcernantLesPrixDachatsApiFp = function(configuration) {
  return {
    /**
     *
     * @summary Récupère les prix d'achats
     * @param {string} productIds identifiants des produits
     * @param {string} xAccessToken Access Token oauth du client
     * @param {number} [storeId] identifiant du magasin (Obligatoire)
     * @param {number} [operationId] identifiant de l&#39;opération commerciale
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {boolean} [lastActivePurchaseCondition] permet de retourner la dernière condition d&#39;achat même périmée
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      productIds,
      xAccessToken,
      storeId,
      operationId,
      fl,
      lastActivePurchaseCondition,
      options
    ) {
      const localVarFetchArgs = OprationsConcernantLesPrixDachatsApiFetchParamCreator(
        configuration
      ).get(
        productIds,
        xAccessToken,
        storeId,
        operationId,
        fl,
        lastActivePurchaseCondition,
        options
      );
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Récupère les prix d'achats - POST
     * @param {ProductsPostParamVO} body objet json
     * @param {string} xAccessToken Access Token oauth du client (authentification faible)
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(body, xAccessToken, fl, options) {
      const localVarFetchArgs = OprationsConcernantLesPrixDachatsApiFetchParamCreator(
        configuration
      ).post(body, xAccessToken, fl, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }
  };
};
/**
 * OprationsConcernantLesPrixDachatsApi - factory interface
 * @export
 */
export const OprationsConcernantLesPrixDachatsApiFactory = function(
  configuration,
  fetch,
  basePath
) {
  return {
    /**
     *
     * @summary Récupère les prix d'achats
     * @param {string} productIds identifiants des produits
     * @param {string} xAccessToken Access Token oauth du client
     * @param {number} [storeId] identifiant du magasin (Obligatoire)
     * @param {number} [operationId] identifiant de l&#39;opération commerciale
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {boolean} [lastActivePurchaseCondition] permet de retourner la dernière condition d&#39;achat même périmée
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      productIds,
      xAccessToken,
      storeId,
      operationId,
      fl,
      lastActivePurchaseCondition,
      options
    ) {
      return OprationsConcernantLesPrixDachatsApiFp(configuration).get(
        productIds,
        xAccessToken,
        storeId,
        operationId,
        fl,
        lastActivePurchaseCondition,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Récupère les prix d'achats - POST
     * @param {ProductsPostParamVO} body objet json
     * @param {string} xAccessToken Access Token oauth du client (authentification faible)
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(body, xAccessToken, fl, options) {
      return OprationsConcernantLesPrixDachatsApiFp(configuration).post(
        body,
        xAccessToken,
        fl,
        options
      )(fetch, basePath);
    }
  };
};
/**
 * OprationsConcernantLesPrixDachatsApi - object-oriented interface
 * @export
 * @class OprationsConcernantLesPrixDachatsApi
 * @extends {BaseAPI}
 */
export class OprationsConcernantLesPrixDachatsApi extends BaseAPI {
  /**
   *
   * @summary Récupère les prix d'achats
   * @param {} productIds identifiants des produits
   * @param {} xAccessToken Access Token oauth du client
   * @param {} [storeId] identifiant du magasin (Obligatoire)
   * @param {} [operationId] identifiant de l&#39;opération commerciale
   * @param {} [fl] permet de filtrer les réponses de l&#39;api
   * @param {} [lastActivePurchaseCondition] permet de retourner la dernière condition d&#39;achat même périmée
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OprationsConcernantLesPrixDachatsApi
   */
  get(
    productIds,
    xAccessToken,
    storeId,
    operationId,
    fl,
    lastActivePurchaseCondition,
    options
  ) {
    return OprationsConcernantLesPrixDachatsApiFp(this.configuration).get(
      productIds,
      xAccessToken,
      storeId,
      operationId,
      fl,
      lastActivePurchaseCondition,
      options
    )(this.fetch, this.basePath);
  }
  /**
   *
   * @summary Récupère les prix d'achats - POST
   * @param {} body objet json
   * @param {} xAccessToken Access Token oauth du client (authentification faible)
   * @param {} [fl] permet de filtrer les réponses de l&#39;api
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OprationsConcernantLesPrixDachatsApi
   */
  post(body, xAccessToken, fl, options) {
    return OprationsConcernantLesPrixDachatsApiFp(this.configuration).post(
      body,
      xAccessToken,
      fl,
      options
    )(this.fetch, this.basePath);
  }
}
/**
 * OprationsConcernantLesPrixDeVentesApi - fetch parameter creator
 * @export
 */
export const OprationsConcernantLesPrixDeVentesApiFetchParamCreator = function(
  configuration
) {
  return {
    /**
     *
     * @summary Récupère les prix de ventes courants des produits (sauf si période ou opération spécifié ; dans ce cas on affiche tous les prix)
     * @param {string} productIds identifiants des produits (séparés par une virgule)
     * @param {number} [storeId] identifiant du magasin (prix national si non renseigné)
     * @param {number} [operationId] identifiant de l&#39;opération commerciale
     * @param {string} [from] permet d&#39;afficher tous les prix qui se chevauchent &#39;à partir de&#39; ; date au format yyyy-MM-dd
     * @param {string} [to] permet d&#39;afficher tous les prix qui se chevauchent &#39;pendant la période&#39; ; dates au format yyyy-MM-dd
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {string} [xAccessToken] Access Token oauth du client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      productIds,
      storeId,
      operationId,
      from,
      to,
      fl,
      xAccessToken,
      options = {}
    ) {
      // verify required parameter 'productIds' is not null or undefined
      if (productIds === null || productIds === undefined) {
        throw new RequiredError(
          'productIds',
          'Required parameter productIds was null or undefined when calling get.'
        );
      }
      const localVarPath = `/prices`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (productIds !== undefined) {
        localVarQueryParameter['productIds'] = productIds;
      }
      if (storeId !== undefined) {
        localVarQueryParameter['storeId'] = storeId;
      }
      if (operationId !== undefined) {
        localVarQueryParameter['operationId'] = operationId;
      }
      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }
      if (to !== undefined) {
        localVarQueryParameter['to'] = to;
      }
      if (fl) {
        localVarQueryParameter['fl'] = fl;
      }
      if (xAccessToken !== undefined && xAccessToken !== null) {
        localVarHeaderParameter['X-Access-Token'] = String(xAccessToken);
      } else {
        localVarHeaderParameter['X-Access-Token'] = String(
          configuration.apiKey
        );
      }
      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        { 'X-ClientApiKey': configuration.apiKey },
        localVarHeaderParameter,
        options.headers
      );
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Récupère les prix de ventes courants des produits (sauf si période ou opération spécifié ; dans ce cas on affiche tous les prix) - via POST
     * @param {ProductsPostParamVO} body objet json
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {string} [xAccessToken] Access Token oauth du client (authentification faible)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(body, fl, xAccessToken, options = {}) {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling post.'
        );
      }
      const localVarPath = `/prices`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      if (fl) {
        localVarQueryParameter['fl'] = fl;
      }
      if (xAccessToken !== undefined && xAccessToken !== null) {
        localVarHeaderParameter['X-Access-Token'] = String(xAccessToken);
      }
      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        'ProductsPostParamVO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};
/**
 * OprationsConcernantLesPrixDeVentesApi - functional programming interface
 * @export
 */
export const OprationsConcernantLesPrixDeVentesApiFp = function(configuration) {
  return {
    /**
     *
     * @summary Récupère les prix de ventes courants des produits (sauf si période ou opération spécifié ; dans ce cas on affiche tous les prix)
     * @param {string} productIds identifiants des produits (séparés par une virgule)
     * @param {number} [storeId] identifiant du magasin (prix national si non renseigné)
     * @param {number} [operationId] identifiant de l&#39;opération commerciale
     * @param {string} [from] permet d&#39;afficher tous les prix qui se chevauchent &#39;à partir de&#39; ; date au format yyyy-MM-dd
     * @param {string} [to] permet d&#39;afficher tous les prix qui se chevauchent &#39;pendant la période&#39; ; dates au format yyyy-MM-dd
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {string} [xAccessToken] Access Token oauth du client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(productIds, storeId, operationId, from, to, fl, xAccessToken, options) {
      const localVarFetchArgs = OprationsConcernantLesPrixDeVentesApiFetchParamCreator(
        configuration
      ).get(
        productIds,
        storeId,
        operationId,
        from,
        to,
        fl,
        xAccessToken,
        options
      );
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Récupère les prix de ventes courants des produits (sauf si période ou opération spécifié ; dans ce cas on affiche tous les prix) - via POST
     * @param {ProductsPostParamVO} body objet json
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {string} [xAccessToken] Access Token oauth du client (authentification faible)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(body, fl, xAccessToken, options) {
      const localVarFetchArgs = OprationsConcernantLesPrixDeVentesApiFetchParamCreator(
        configuration
      ).post(body, fl, xAccessToken, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }
  };
};
/**
 * OprationsConcernantLesPrixDeVentesApi - factory interface
 * @export
 */
export const OprationsConcernantLesPrixDeVentesApiFactory = function(
  configuration,
  fetch,
  basePath
) {
  return {
    /**
     *
     * @summary Récupère les prix de ventes courants des produits (sauf si période ou opération spécifié ; dans ce cas on affiche tous les prix)
     * @param {string} productIds identifiants des produits (séparés par une virgule)
     * @param {number} [storeId] identifiant du magasin (prix national si non renseigné)
     * @param {number} [operationId] identifiant de l&#39;opération commerciale
     * @param {string} [from] permet d&#39;afficher tous les prix qui se chevauchent &#39;à partir de&#39; ; date au format yyyy-MM-dd
     * @param {string} [to] permet d&#39;afficher tous les prix qui se chevauchent &#39;pendant la période&#39; ; dates au format yyyy-MM-dd
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {string} [xAccessToken] Access Token oauth du client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(productIds, storeId, operationId, from, to, fl, xAccessToken, options) {
      return OprationsConcernantLesPrixDeVentesApiFp(configuration).get(
        productIds,
        storeId,
        operationId,
        from,
        to,
        fl,
        xAccessToken,
        options
      )(fetch, basePath);
    },
    /**
     *
     * @summary Récupère les prix de ventes courants des produits (sauf si période ou opération spécifié ; dans ce cas on affiche tous les prix) - via POST
     * @param {ProductsPostParamVO} body objet json
     * @param {Array&lt;string&gt;} [fl] permet de filtrer les réponses de l&#39;api
     * @param {string} [xAccessToken] Access Token oauth du client (authentification faible)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(body, fl, xAccessToken, options) {
      return OprationsConcernantLesPrixDeVentesApiFp(configuration).post(
        body,
        fl,
        xAccessToken,
        options
      )(fetch, basePath);
    }
  };
};
/**
 * OprationsConcernantLesPrixDeVentesApi - object-oriented interface
 * @export
 * @class OprationsConcernantLesPrixDeVentesApi
 * @extends {BaseAPI}
 */
export class OprationsConcernantLesPrixDeVentesApi extends BaseAPI {
  /**
   *
   * @summary Récupère les prix de ventes courants des produits (sauf si période ou opération spécifié ; dans ce cas on affiche tous les prix)
   * @param {} productIds identifiants des produits (séparés par une virgule)
   * @param {} [storeId] identifiant du magasin (prix national si non renseigné)
   * @param {} [operationId] identifiant de l&#39;opération commerciale
   * @param {} [from] permet d&#39;afficher tous les prix qui se chevauchent &#39;à partir de&#39; ; date au format yyyy-MM-dd
   * @param {} [to] permet d&#39;afficher tous les prix qui se chevauchent &#39;pendant la période&#39; ; dates au format yyyy-MM-dd
   * @param {} [fl] permet de filtrer les réponses de l&#39;api
   * @param {} [xAccessToken] Access Token oauth du client
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OprationsConcernantLesPrixDeVentesApi
   */
  get(productIds, storeId, operationId, from, to, fl, xAccessToken, options) {
    return OprationsConcernantLesPrixDeVentesApiFp(this.configuration).get(
      productIds,
      storeId,
      operationId,
      from,
      to,
      fl,
      xAccessToken,
      options
    )(this.fetch, this.basePath);
  }
  /**
   *
   * @summary Récupère les prix de ventes courants des produits (sauf si période ou opération spécifié ; dans ce cas on affiche tous les prix) - via POST
   * @param {} body objet json
   * @param {} [fl] permet de filtrer les réponses de l&#39;api
   * @param {} [xAccessToken] Access Token oauth du client (authentification faible)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OprationsConcernantLesPrixDeVentesApi
   */
  post(body, fl, xAccessToken, options) {
    return OprationsConcernantLesPrixDeVentesApiFp(this.configuration).post(
      body,
      fl,
      xAccessToken,
      options
    )(this.fetch, this.basePath);
  }
}
