import React, { Component } from 'react';
import { translate, Trans } from 'react-i18next';
import { Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Button } from '../../../components';

class PackList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { packs, selectedPacks } = this.props;

    return (
      <Table responsive>
        <tbody>
          {packs.map((pack, key) => {
            return [
              <tr key={key}>
                <td width="5%" className="text-left p-1">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value={pack._id}
                        checked={
                          selectedPacks.findIndex(s => s === pack._id) > -1
                        }
                        onChange={event => this.props.onChange(event)}
                      />
                      <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </td>
                <td className="text-left p-1">{pack.name}</td>
                <td width="5%" className="text-right p-1">
                  <Button
                    icon
                    color="info"
                    size="sm"
                    onClick={() =>
                      this.setState({
                        [`pack${key}`]: !this.state[`pack${key}`]
                      })
                    }
                  >
                    <i
                      className={`now-ui-icons ${
                        this.state[`pack${key}`]
                          ? 'arrows-1_minimal-up'
                          : 'arrows-1_minimal-down'
                      }`}
                    />
                  </Button>{' '}
                </td>
              </tr>,
              <tr
                key={`pack${key}`}
                className={this.state[`pack${key}`] ? '' : 'tr-disable'}
              >
                <td colSpan={3}>
                  <ul>
                    {pack.operations.map((op, opKey) => {
                      return <li key={opKey}>{op.name}</li>;
                    })}
                  </ul>
                </td>
              </tr>
            ];
          })}
        </tbody>
      </Table>
    );
  }
}

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPacks: []
    };
  }

  componentWillReceiveProps({ selectedPacks }) {
    this.setState({ selectedPacks });
  }

  onChange(event) {
    let { selectedPacks } = this.state;

    if (event.target.checked) {
      selectedPacks.push(event.target.value);
    } else {
      let index = selectedPacks.findIndex(g => g === event.target.value);

      if (index > -1) selectedPacks.splice(index, 1);
    }

    this.props.onChangePacks(selectedPacks);
  }

  render() {
    let { selectedPacks } = this.state,
      { grouped } = this.props;

    return (
      <div className={'package'}>
        <h5 className="info-text">
          <Trans>Select the packages</Trans>
        </h5>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={12}>
            {grouped.length ? (
              grouped.map((group, key) => {
                return (
                  <Table key={key} responsive className={'border-0'}>
                    <thead className="group-header">
                      <tr>
                        <th className="text-center p-1">
                          <h6 className={'m-0'}>{group.name}</h6>
                        </th>
                        <th width="5%" className="text-right p-1">
                          <Button
                            icon
                            color="info"
                            size="sm"
                            onClick={() =>
                              this.setState({
                                [`group${key}`]:
                                  this.state[`group${key}`] === 'tr-disable'
                                    ? ''
                                    : 'tr-disable'
                              })
                            }
                          >
                            <i
                              className={`now-ui-icons ${
                                this.state[`group${key}`] === 'tr-disable'
                                  ? 'ui-1_simple-add'
                                  : 'ui-1_simple-delete'
                              }`}
                            />
                          </Button>{' '}
                        </th>
                      </tr>
                    </thead>
                    <tbody className={this.state[`group${key}`]}>
                      <tr>
                        <td colSpan={2}>
                          <PackList
                            packs={group.packs}
                            selectedPacks={selectedPacks}
                            onChange={event => this.onChange(event)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                );
              })
            ) : (
              <p className={'text-not-found text-center'}>
                <Trans>No packages found</Trans>
              </p>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default translate('translations-fr')(Step3);
