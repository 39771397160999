import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { PanelHeader, Spinner } from '../../../components';
import { Link } from 'react-router-dom';
import api from '../../../helpers/catalogs/leroymerlin/index';
import { byType } from '../../../helpers/actions/leroymerlin';

class Sections extends Component {
  state = {
    loading: true,
    sections: []
  };

  load() {
    this.setState({ loading: true });
    let { match, dispatch } = this.props;

    this.setState(
      { type: match.params.type || api.Products.TypeEnum.Internal },
      () => {
        dispatch(byType({ type: this.state.type }))
          .then(({ data }) => {
            this.setState({ sections: data, loading: false });
          })
          .catch(() => this.setState({ loading: false, options: [] }));
      }
    );
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate() {
    let { match } = this.props,
      { type } = this.state;

    if (
      match.params.type &&
      match.params.type.toLowerCase() !== type.toLowerCase()
    ) {
      this.load();
    }
  }

  render() {
    let { loading, sections, type = 'internal' } = this.state,
      options = {};

    Object.keys(api.Products.TypeEnum).forEach(
      s => (options[s.toLowerCase()] = s.toUpperCase())
    );

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Row>
            <Col>
              <Card>
                <CardBody className="text-center">
                  <ul className="list-inline text-center">
                    {Object.keys(options).map(key => (
                      <li
                        key={key}
                        className={`list-inline-item ${
                          type === key ? 'active' : ''
                        }`}
                        style={{ padding: '0 20px' }}
                      >
                        {type === key ? (
                          <strong>
                            <Link to={`/catalogs/${key}`}>
                              <Trans>{options[key]}</Trans>
                            </Link>
                          </strong>
                        ) : (
                          <Link to={`/catalogs/${key}`}>
                            <Trans>{options[key]}</Trans>
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {sections.map((s, i) => (
              <Col md={4} xs={12} key={i}>
                <Card>
                  <CardHeader className="text-center">
                    <h6>
                      <Link to={`/catalogs/${type}/${s.id}/`}>{s.label}</Link>{' '}
                    </h6>
                    <hr />
                  </CardHeader>
                  <CardBody className="text-center">
                    <span className="section-product-count">
                      {s.productsCount}
                    </span>{' '}
                    <Trans>products</Trans>
                  </CardBody>
                  <CardFooter className="text-right clearfix">
                    <Link to={`/catalogs/${type}/${s.id}/`}>
                      <Trans>Explore</Trans>
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Sections));
