import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table
} from 'reactstrap';
import { PanelHeader } from '../../../components';
import Pagination from '../../../components/Pagination/SimplePagination';
import { Link } from 'react-router-dom';
import {
  byTypeAndId,
  productsByTypeAndIdGet
} from '../../../helpers/actions/leroymerlin';

class SubSections extends Component {
  state = {
    sections: [],
    activeProduct: null
  };

  load() {
    let { section, type } = this.props.match.params;
    let { dispatch } = this.props;

    dispatch(byTypeAndId({ type, section }))
      .then(({ data }) => {
        this.setState({ ...data });
      })
      .catch(() => this.setState({ loading: false, options: [] }));

    this.getPage(1, 20);
  }

  getPage(page, maxPerPage = 20) {
    let { type, section } = this.props.match.params;
    let { dispatch } = this.props;

    dispatch(productsByTypeAndIdGet({ type, section, page, maxPerPage }))
      .then(({ data }) => {
        this.setState({ products: data });
      })
      .catch(() => this.setState({ products: [] }));
  }

  componentWillMount() {
    this.load();
  }

  componentDidUpdate() {
    let { match } = this.props;
    if (parseInt(match.params.section) !== parseInt(this.state.id)) {
      this.load();
    }
  }

  shouldComponentUpdate() {
    return true;
  }

  render() {
    let {
        label,
        parents = [],
        children = [],
        productsCount,
        products = {},
        prices = [],
        activeProduct
      } = this.state,
      { type } = this.props.match.params;

    if (!children) {
      children = [];
    }

    const productList =
      productsCount && products && products.data
        ? products.data.map((p, index) => {
            let price =
              prices.find(x => parseInt(x.productId) === parseInt(p.id)) || {};

            return (
              <Col
                xs={12}
                md={6}
                className={`product-item ${index % 2 === 0 ? 'left' : 'right'}`}
                key={index}
              >
                <Card>
                  <CardHeader>
                    <div className="img-container product-item__image">
                      <img
                        alt=""
                        src={
                          p.media && p.media.data && p.media.data.length
                            ? p.media.data[0].url
                            : 'https://via.placeholder.com/160x120?text=image%20non%20disponible'
                        }
                      />
                    </div>
                  </CardHeader>
                  <CardBody>
                    <h6 className="text-center">{p.label}</h6>
                    <Table>
                      <tbody>
                        <tr>
                          <th>
                            <Trans>Reference</Trans>:
                          </th>
                          <td className="text-right">{p.id}</td>
                        </tr>
                        <tr>
                          <th>
                            <Trans>Category</Trans>:
                          </th>
                          <td className="text-right">{p.category}</td>
                        </tr>
                        <tr>
                          <th>
                            <Trans>Price</Trans>:
                          </th>
                          <td className="text-right">
                            {price.price} {price.currency}
                          </td>
                        </tr>
                        {p.characteristics && p.id === activeProduct ? (
                          [
                            <tr key={`characteristics-${p.id}`}>
                              <td colSpan={2}>
                                <Button
                                  color={'link'}
                                  onClick={() =>
                                    this.setState({ activeProduct: null })
                                  }
                                >
                                  <Trans>See less</Trans>
                                </Button>
                              </td>
                            </tr>,
                            p.characteristics.data.map((c, index) => (
                              <tr key={index} style={{ width: '50%' }}>
                                <td>{c.shortLabel}</td>
                                <td align="right" className="text-right">
                                  {c.values.join(', ')}
                                </td>
                              </tr>
                            ))
                          ]
                        ) : (
                          <tr>
                            <td colSpan={2}>
                              <Button
                                color={'link'}
                                onClick={() =>
                                  this.setState({ activeProduct: p.id })
                                }
                              >
                                <Trans>See more</Trans>
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            );
          })
        : null;

    const Paginator =
      products.totalCount > 20 ? (
        <Card>
          <CardBody className="text-center">
            <Pagination
              className="text-center"
              count={products.totalCount}
              page={products.currentPage || 1}
              maxPerPage={20}
              onPageChange={(pageNumber, maxPerPage) =>
                this.getPage(pageNumber, maxPerPage)
              }
            />
          </CardBody>
        </Card>
      ) : null;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h6>
                    {label}
                    <span className="float-right">
                      {`${productsCount} `}
                      <Trans>products</Trans>
                    </span>
                  </h6>
                </CardHeader>
                <CardBody>
                  <ul className="list-inline">
                    {parents.map((p, i) => (
                      <li key={p.id + i} className="list-inline-item">
                        <Link
                          to={`/catalogs/${type}/${p.id ? p.id + '/' : ''}`}
                        >
                          {p.label}
                        </Link>{' '}
                        /
                      </li>
                    ))}
                    <li className="list-inline-item">{label}</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {children.length ? (
              <Col xs={12} md={4} lg={3}>
                {children.map((s, i) => (
                  <Card key={i}>
                    <CardBody>
                      <Link to={`/catalogs/${type}/${s.id}/`}>
                        {s.label}{' '}
                        <span className="float-right">{s.productsCount}</span>
                      </Link>
                    </CardBody>
                  </Card>
                ))}
              </Col>
            ) : null}
            {children.length ? (
              <Col xs={12} md={8} lg={9}>
                {Paginator}
                <Row>{productList}</Row>
                {Paginator}
              </Col>
            ) : (
              <Col xs={12}>
                {Paginator}
                <Row>{productList}</Row>
                <Pagination
                  count={products.totalCount}
                  page={products.currentPage || 1}
                  maxPerPage={20}
                  onPageChange={(pageNumber, maxPerPage) =>
                    this.getPage(pageNumber, maxPerPage)
                  }
                />
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(SubSections));
