import {
  NomenclaturesApi,
  ProductsApi,
  CharacteristicsApi,
  TypeEnum,
  OriginEnum
} from './api';
import 'whatwg-fetch';

const BASE_PATH = 'https://api-gateway.leroymerlin.fr/api-product/v2';
const key = 'cokDrieLSEt3piqfCGRctImflz1RjbfG';

export default {
  Nomenclatures: new NomenclaturesApi(
    {
      apiKey: key
    },
    BASE_PATH
  ),
  Products: new ProductsApi(
    {
      apiKey: key
    },
    BASE_PATH
  ),
  Characteristics: new CharacteristicsApi(
    {
      apiKey: key
    },
    BASE_PATH
  ),
  OriginEnum,
  TypeEnum
};
