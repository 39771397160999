import * as Price from './api';

const BASE_PATH = 'https://api-gateway.leroymerlin.fr/api-price/v1';
const key = 'siqbQKam8lnmoqOMx1pF3ntJrtEvlrfr';

export default {
  Price: new Price.OprationsConcernantLesPrixDeVentesApi(
    {
      apiKey: key
    },
    BASE_PATH
  )
};
