import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isEmpty } from 'react-redux-firebase';
import { auth } from 'firebase';

class UpdateToken extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.unregisterAuthObserver = null;
  }

  componentDidMount() {
    this.loadToken();
    // refresh token every 50 minutes to avoid disconnections
    this.timer = setInterval(() => this.forceUpdateToken(), 3000000); // 50 min 3000000
    // detect any change in user and update local token
    this.unregisterAuthObserver = auth().onAuthStateChanged(async user => {
      if (user) {
        auth()
          .currentUser.getIdToken(true)
          .then(token => {
            localStorage.setItem('token', token);
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  }

  componentWillReceiveProps() {
    this.loadToken();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.unregisterAuthObserver();
  }

  forceUpdateToken() {
    try {
      auth()
        .currentUser.getIdToken(true)
        .then(token => {
          localStorage.setItem('token', token);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {}
  }

  updateUserToken() {
    if (!isEmpty(this.props.auth)) {
      localStorage.setItem(
        'token',
        this.props.auth.stsTokenManager.accessToken
      );
    } else {
      localStorage.setItem('token', null);
      window.location.href = window.location.origin + '/login';
    }
  }

  loadToken() {
    if (!isEmpty(this.props.auth)) {
      if (Date.now() >= this.props.auth.stsTokenManager.expirationTime) {
        // Update token to use in API requests
        localStorage.setItem(
          'token',
          this.props.auth.stsTokenManager.accessToken
        );
      }
    } else {
      localStorage.setItem('token', null);
    }
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => {
  return { auth: state.fb.auth };
};

export default compose(
  connect(mapStateToProps),
  firebaseConnect()
)(UpdateToken);
