import React from 'react';
import { Table } from 'reactstrap';

class TableCustom extends React.Component {
  constructor(props = {}) {
    super(props);

    this.state = {
      cardMode: false,
      opens: []
    };
  }

  evaluateMode() {
    let { cardMode } = this.state,
      { minWidth = 576 } = this.props;
    let windowWidth = window.innerWidth;

    if (!cardMode && windowWidth <= minWidth) {
      this.setState({ cardMode: true });
    } else if (cardMode && windowWidth > minWidth) {
      this.setState({ cardMode: false });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.evaluateMode());
    this.evaluateMode();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.evaluateMode());
  }

  changeOpen(key) {
    let { opens } = this.state;
    let index = opens.findIndex(o => o === key);

    if (index === -1) opens.push(key);
    else opens.splice(index, 1);

    this.setState({ opens });
  }

  render() {
    let { cardMode, opens } = this.state;
    let { heads, rows, accordion, principal = 0, emptyMessage } = this.props;

    return !cardMode ? (
      <Table responsive className={`table-striped ${this.props.className}`}>
        <thead>
          <tr>
            {heads.map((th, key) => {
              return (
                <th key={key} className={th.className}>
                  {th.value.toUpperCase()}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, key) => {
            return (
              <tr className={row.className} key={`row-${key}`}>
                {row.data.map((d, keyData) => {
                  return (
                    <td
                      key={`cell-${keyData}`}
                      className={d.className}
                      {...d.properties}
                    >
                      {d.value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {rows.length ? null : (
            <tr>
              <td colSpan={heads.length} className={'text-center'}>
                {this.props.emptyMessage}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    ) : (
      <Table
        responsive
        className={`reduced-table ${accordion ? 'table-accordion' : ''}`}
      >
        {rows.length ? (
          <tbody>
            {rows.map((row, key) => {
              return [
                <tr key={`head-${key}`}>
                  <th colSpan={2}>
                    {accordion ? (
                      // eslint-disable-next-line
                      <a
                        data-toggle="collapse"
                        aria-expanded={
                          opens.findIndex(o => o === key) === -1 ? true : false
                        }
                        className={
                          opens.findIndex(o => o === key) === -1
                            ? 'expanded'
                            : ''
                        }
                        ref={'collapse' + key}
                        onClick={() => this.changeOpen(key)}
                      >
                        <i
                          className={`now-ui-icons ${
                            opens.findIndex(o => o === key) === -1
                              ? 'arrows-1_minimal-down'
                              : 'arrows-1_minimal-up'
                          } mr-2`}
                        />
                      </a>
                    ) : null}
                    {row.data[principal].value}
                  </th>
                </tr>,
                row.data.map((d, keyData) => {
                  return keyData !== principal ? (
                    <tr
                      key={`cell-${keyData}`}
                      className={`${
                        accordion &&
                        !d.fixed &&
                        opens.findIndex(o => o === key) === -1
                          ? 'hide'
                          : ''
                      }`}
                    >
                      {!d.actions ? (
                        <td className={'text-left'}>{heads[keyData].value}</td>
                      ) : null}
                      <td
                        colSpan={d.actions ? 2 : 1}
                        className={d.actions ? 'text-right' : ''}
                      >
                        {d.value}
                      </td>
                    </tr>
                  ) : null;
                })
              ];
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td className={'text-center'}>{emptyMessage}</td>
            </tr>
          </tbody>
        )}
      </Table>
    );
  }
}

TableCustom.propTypes = {};

export default TableCustom;
